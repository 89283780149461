.rdrInRange {
  background-color: #efe8ff;
}

.rdrDayStartPreview,
.rdrStartEdge {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rdrDayEndPreview,
.rdrEndEdge {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrStartEdge ~ .rdrEndEdge ~ .rdrSelected ~ .rdrDayNumber span {
  color: #9f55ff;
}
